.TablePage-container{
  background-image: url('../assets/PomeBG.svg'); /* Replace with the actual path to your image */
}

button.call-button {
 animation: pulse-red 2s infinite;
 transition: background-color 0.6s ease;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(255, 82, 82, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
