@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom styles go here */
body, html {
  height: 100%;
  margin: 0;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
}
 
::-webkit-scrollbar-track {
  background: #ddd;
}
 
::-webkit-scrollbar-thumb {
  background: #464646; 
}
/************************************/
.swipeable-list-item__content {
  display: inline-block!important;
}

.swipeable-list-item__trailing-actions {
  background-color: red;
  margin-right: -10px;
}
.swipe-action {
  align-items: center!important;
  color: white!important;
  padding-left: 10px!important;
}
